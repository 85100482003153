(function () {
  'use strict';

  angular
  .module('neo.home.ajuntaments.ajuntament')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.ajuntaments.ajuntament', {
      url: '/{ajuntamentId}',
      abstract: true,
      templateUrl: 'home/ajuntaments/ajuntament/ajuntament.tpl.html',
      controller: 'AjuntamentCtrl',
      controllerAs: 'vm',
      resolve: {
        ajuntament: function ($stateParams, Ajuntaments, $q) {
          var deferred = $q.defer();
          Ajuntaments.get({id: $stateParams.ajuntamentId},
            function (data) {
              // returned 204
              deferred.resolve(data);
            },
            function (response) {
              deferred.reject(response);
            }
          );
          return deferred.promise;
        },
        towns: function (Poblacions, $q) {
          var deferred = $q.defer();
          Poblacions.query(function (data) {
              deferred.resolve(data)
            },
            function (response) {
              deferred.reject(response)
            }
          );
          return deferred.promise;
        }
      }
    })
    ;
  }
}());
